
import { I18n } from "@aws-amplify/core";
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function GlossarySelector({ glossary, setGlossary, model}) {

  const handleChange = (event) => {
    setGlossary(event.target.value);
  };

    return (
        <FormControl size="small" sx={{ minWidth: 120  /*, display: !(["eu2es", "es2eu"].includes(model)) ? 'none' : 'flex'*/}}>
            <InputLabel id="demo-simple-select-autowidth-label">{I18n.get("glossary")}</InputLabel>
            <Select
                labelId="glossary-label"
                id="glossary-select"
                value={glossary}
                onChange={handleChange}
                autoWidth
                label={I18n.get("glossary")}
                sx={{ maxHeight: 45 }}
            >
                <MenuItem value={""}>{I18n.get("glossary-none")}</MenuItem>
                <MenuItem value={"ACE_2024-11-14"}>ACE</MenuItem>
                <MenuItem value={"AIC-2024-10-18"}>AIC</MenuItem>
                <MenuItem value={"MAFEX_2024-10-18"}>MAFEX</MenuItem>
                <MenuItem value={"INVEMA_2024-11-14"}>INVEMA</MenuItem>
                <MenuItem value={"mt-glosario-testing"}>Testing MT</MenuItem>
            </Select>
        </FormControl>
    );
}

export default GlossarySelector;
  